import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import * as allpaytransactionService from "@/redux/services/allpaytransactionService"

import { AllPayTransaction, SearchDashboard } from "@/models/allpaytransactions.model"

interface TransactionState {
	transactions: AllPayTransaction[]
}

const initialState: TransactionState = {
	transactions: []
}

export const postDashboard = createAsyncThunk("allpay/dashboard", async (data: SearchDashboard) => {
	return await allpaytransactionService.postDashboard(data)
})

const allpaytransactionSlice = createSlice({
	name: "allpaytransaction",
	initialState: initialState,
	reducers: {},
	extraReducers: (builder) => { }
})

export default allpaytransactionSlice.reducer