import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import * as saptransactionService from "@/redux/services/saptransactionService"

import { SAPTransaction, SearchDashboard } from "@/models/saptransactions.model"

interface TransactionState {
  transactions: SAPTransaction[]
}

const initialState: TransactionState = {
  transactions: []
}

export const postDashboard = createAsyncThunk("sap/dashboard", async (data: SearchDashboard) => {
  return await saptransactionService.postDashboard(data)
})

const saptransactionSlice = createSlice({
  name: "saptransaction",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => { }
})

export default saptransactionSlice.reducer