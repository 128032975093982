import { AdminForm, AdminOneRes, AdminRes, SettingForm, SettingRes } from "@/models/admins.model"
import { PostData } from "@/models/ve.model"
import { VEClient } from "@/utils/axios"

export const getSetting = async (): Promise<SettingRes> => {
  const response = await VEClient.get(`/api/setting/`)
  return response.data
}

export const putUpdateSetting = async (data: SettingForm): Promise<void> => {
  const response = await VEClient.put(`/api/setting/`, data)
  return response.data
}

export const getAdmin = async (): Promise<AdminRes> => {
  const response = await VEClient.get(`/api/admin/`)
  return response.data
}

export const postCreateAdmin = async (data: AdminForm): Promise<void> => {
  const response = await VEClient.post(`/api/admin/create`, data)
  return response.data
}

export const postCheckAdmin = async (data: PostData): Promise<AdminOneRes> => {
  const response = await VEClient.post(`/api/admin/check`, data)
  return response.data
}

export const deleteAdmin = async (data: number): Promise<void> => {
  const response = await VEClient.delete(`/api/admin/${data}`)
  return response.data
}

export const updateAWS = async (): Promise<void> => {
  const response = await VEClient.get(`/api/batch/master/hierarchy`)
  return response.data
}

export const updateAWSMapping = async (): Promise<void> => {
  const response = await VEClient.get(`/api/batch/master/mapping`)
  return response.data
}

export const updateKTRD = async (): Promise<void> => {
  const response = await VEClient.get(`/api/batch/vendor/ktrd`)
  return response.data
}

export const updateKNTD = async (): Promise<void> => {
  const response = await VEClient.get(`/api/batch/vendor/kntd`)
  return response.data
}

export const updateOTV = async (): Promise<void> => {
  const response = await VEClient.get(`/api/batch/updateotv`)
  return response.data
}

export const updateActive = async (): Promise<void> => {
  const response = await VEClient.get(`/api/batch/vendor/active`)
  return response.data
}

export const updateInactive = async (): Promise<void> => {
  const response = await VEClient.get(`/api/batch/vendor/inactive`)
  return response.data
}

export const autoP = async (): Promise<void> => {
  const response = await VEClient.get(`/api/batch/evaluate`)
  return response.data
}