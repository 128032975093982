import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import * as aptransactionService from "@/redux/services/aptransactionService"

import { APTransaction, SearchDashboard } from "@/models/aptransactions.model"

interface TransactionState {
	transactions: APTransaction[]
}

const initialState: TransactionState = {
	transactions: []
}

export const postDashboard = createAsyncThunk("ap/dashboard", async (data: SearchDashboard) => {
	return await aptransactionService.postDashboard(data)
})

const aptransactionSlice = createSlice({
	name: "aptransaction",
	initialState: initialState,
	reducers: {},
	extraReducers: (builder) => { }
})

export default aptransactionSlice.reducer