import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import * as vendorService from '@/redux/services/vendorService'
import { Vendor, SearchVendorDashboard, UpdateVendor, SearchProduct, SearchListVendor, SearchTransaction, TotalVendorRes, CreateVendor, SearchVendorCode } from '@/models/vendors.model'
import { RootState } from "@/redux/store"

interface VendorState {
  vendors: Vendor[]
  totals: TotalVendorRes
}

const initialState: VendorState = {
  vendors: [],
  totals: {
    status: 200,
    message: "ok",
    count_scg: 0,
    count_non_scg: 0,
    count_non_scg_active: 0,
    count_non_scg_inactive: 0,
    count_transaction: 0,
    count_no_transaction: 0,
    count_evaluated: 0,
    count_not_evaluated: 0,
  }
}

export const getTotalVendor = createAsyncThunk("vendor/total", async () => {
  return await vendorService.getTotalVendor()
})

export const postDashboard = createAsyncThunk("vendor/dashboard", async (data: SearchVendorDashboard) => {
  return await vendorService.postDashboard(data)
})

export const postVendorCode = createAsyncThunk("vendor/code", async (data: SearchVendorCode) => {
  return await vendorService.postVendorCode(data)
})

export const postListVendor = createAsyncThunk("vendor/list", async (data: SearchListVendor) => {
  return await vendorService.postListVendor(data)
})

export const postCreateVendor = createAsyncThunk("vendor/create", async (data: CreateVendor) => {
  return await vendorService.postCreateVendor(data)
})

export const postUpdateVendor = createAsyncThunk("vendor/update", async (data: UpdateVendor) => {
  return await vendorService.postUpdateVendor(data)
})

export const postTransactionVendor = createAsyncThunk("vendor/transaction", async (data: SearchTransaction) => {
  return await vendorService.postTransactionVendor(data)
})

export const postProductVendor = createAsyncThunk("vendor/prouct", async (data: SearchProduct) => {
  return await vendorService.postProductVendor(data)
})

const vendorSlice = createSlice({
  name: "vendor",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTotalVendor.fulfilled, (state, action) => {
      state.totals = action.payload
    })
  }
})

export const totalSelector = (store: RootState): TotalVendorRes => store.vendor.totals

export default vendorSlice.reducer