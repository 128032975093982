import { CompanyRes, CostCenterRes, EmployeeRes, ProductHierarchiesOneRes, ProductHierarchiesRes, SalesGroupRes, Search, SearchHier } from "@/models/masterdatas.model"
import { VEClient } from "@/utils/axios"

export const getEmployee = async (): Promise<EmployeeRes> => {
  const response = await VEClient.get(`/api/masterdata/employee`)
  return response.data
}

export const getCompany = async (): Promise<CompanyRes> => {
  const response = await VEClient.get(`/api/masterdata/company`)
  return response.data
}

export const getProductHier = async (): Promise<ProductHierarchiesRes> => {
  const response = await VEClient.get(`/api/masterdata/hier`)
  return response.data
}

export const postSearchProductHier = async (data: SearchHier): Promise<ProductHierarchiesOneRes> => {
  const response = await VEClient.post(`/api/masterdata/searchhier`, data)
  return response.data
}

export const getCostCenter = async (): Promise<CostCenterRes> => {
  const response = await VEClient.get(`/api/masterdata/costcenter`)
  return response.data
}

export const getSalesGroup = async (): Promise<SalesGroupRes> => {
  const response = await VEClient.get(`/api/masterdata/salesgroup`)
  return response.data
}

export const postMasterSearch = async (data: Search): Promise<void> => {
  const response = await VEClient.post(`/api/masterdata/search`, data)
  return response.data
}