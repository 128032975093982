import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import * as masterdataService from "@/redux/services/masterdataService"
import { RootState } from "@/redux/store"
import { Company, CostCenter, Employee, ProductHierarchies, SalesGroup, Search, SearchHier } from "@/models/masterdatas.model"

interface MasterdataState {
  employees: Employee[]
  companies: Company[]
  hiers: ProductHierarchies[]
  costcenters: CostCenter[]
  salesgroups: SalesGroup[]
}

const initialState: MasterdataState = {
  employees: [],
  companies: [],
  hiers: [],
  costcenters: [],
  salesgroups: []
}

export const getEmployee = createAsyncThunk("masterdata/employee", async () => {
  return await masterdataService.getEmployee()
})

export const getCompany = createAsyncThunk("masterdata/company", async () => {
  return await masterdataService.getCompany()
})

export const getProductHier = createAsyncThunk("masterdata/hier", async () => {
  return await masterdataService.getProductHier()
})

export const postSearchProductHier = createAsyncThunk("masterdata/searchhier", async (data: SearchHier) => {
  return await masterdataService.postSearchProductHier(data)
})

export const getCostCenter = createAsyncThunk("masterdata/costcenter", async () => {
  return await masterdataService.getCostCenter()
})

export const getSalesGroup = createAsyncThunk("masterdata/salesgroup", async () => {
  return await masterdataService.getSalesGroup()
})

export const postSearch = createAsyncThunk("masterdata/search", async (data: Search) => {
  return await masterdataService.postMasterSearch(data)
})


const masterdataSlice = createSlice({
  name: "masterdata",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEmployee.fulfilled, (state, action) => {
      state.employees = action.payload.result
    })
    builder.addCase(getCompany.fulfilled, (state, action) => {
      state.companies = action.payload.result
    })
    builder.addCase(getProductHier.fulfilled, (state, action) => {
      state.hiers = action.payload.result
    })
    builder.addCase(getCostCenter.fulfilled, (state, action) => {
      state.costcenters = action.payload.result
    })
    builder.addCase(getSalesGroup.fulfilled, (state, action) => {
      state.salesgroups = action.payload.result
    })
  }
})

export const employeeSelector = (store: RootState): Employee[] => store.masterdata.employees
export const companySelector = (store: RootState): Company[] => store.masterdata.companies
export const hierSelector = (store: RootState): ProductHierarchies[] => store.masterdata.hiers
export const costCenterSelector = (store: RootState): CostCenter[] => store.masterdata.costcenters
export const salesGroupSelector = (store: RootState): SalesGroup[] => store.masterdata.salesgroups

export default masterdataSlice.reducer