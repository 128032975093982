import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import * as reportService from "@/redux/services/reportService"
import { FormReportBusinessData, ReportCompCode, ReportPeriod } from "@/models/report.model"
import { INF_CHECK_HIERARCHY_RES_DATA } from "@/models/aws.model"
import { RootState } from "@/redux/store"

interface ReportState {
  reportHierarchy: INF_CHECK_HIERARCHY_RES_DATA
}

const initialState: ReportState = {
  reportHierarchy: { branch: [], hierarchy_yaml_version: 0, cost_center_version: 0 }
}

export const getReportHierarchy = createAsyncThunk("report/hierarchy", async () => {
  return await reportService.getReportHierarchy()
})

export const postReportRank = createAsyncThunk("report/rank", async (data: ReportPeriod) => {
  return await reportService.postReportRank(data)
})

export const postReportNotEvaluate = createAsyncThunk("report/notevaluate", async (data: ReportPeriod) => {
  return await reportService.postReportNotEvaluate(data)
})

export const postReportListNotEvaluate = createAsyncThunk("report/listnotevaluate", async (data: ReportPeriod) => {
  return await reportService.postReportListNotEvaluate(data)
})

export const postReportEvaluated = createAsyncThunk("report/evaluated", async (data: ReportPeriod) => {
  return await reportService.postReportEvaluated(data)
})

export const postReportListEvaluated = createAsyncThunk("report/listevaluated", async (data: ReportPeriod) => {
  return await reportService.postReportListEvaluated(data)
})

export const postReportBusiness = createAsyncThunk("report/business", async (data: FormReportBusinessData) => {
  return await reportService.postReportBusiness(data)
})

export const postReportCompCode = createAsyncThunk("report/compcode", async (data: ReportCompCode) => {
  return await reportService.postReportCompCode(data)
})

export const postReportCompCodeList = createAsyncThunk("report/compcodelist", async (data: ReportCompCode) => {
  return await reportService.postReportCompCodeList(data)
})

export const postReportReason = createAsyncThunk("report/reason", async (data: ReportPeriod) => {
  return await reportService.postReportReason(data)
})

const reportSlice = createSlice({
  name: "report",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getReportHierarchy.fulfilled, (state, action) => {
      state.reportHierarchy = action.payload.result
    })
  }
})

export const reportHierarchySelector = (store: RootState): INF_CHECK_HIERARCHY_RES_DATA => store.report.reportHierarchy

export default reportSlice.reducer