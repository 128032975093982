import { ProductVendorRes, SearchVendorDashboard, SearchListVendor, SearchProduct, UpdateVendor, VendorRes, DashboardVendorRes, SearchTransaction, TransactionVendorRes, TotalVendorRes, CreateVendor, VendorOneRes, SearchVendorCode } from "@/models/vendors.model"
import { VEClient } from "@/utils/axios"

export const getTotalVendor = async (): Promise<TotalVendorRes> => {
  const response = await VEClient.get(`/api/vendor/total`)
  return response.data
}

export const postDashboard = async (data: SearchVendorDashboard): Promise<DashboardVendorRes> => {
  const response = await VEClient.post(`/api/vendor/dashboard`, data)
  return response.data
}

export const postVendorCode = async (data: SearchVendorCode): Promise<VendorOneRes> => {
  const response = await VEClient.post(`/api/vendor/code`, data)
  return response.data
}

export const postListVendor = async (data: SearchListVendor): Promise<VendorRes> => {
  const response = await VEClient.post(`/api/vendor/list`, data)
  return response.data
}

export const postCreateVendor = async (data: CreateVendor): Promise<void> => {
  const response = await VEClient.post(`/api/vendor/create`, data)
  return response.data
}

export const postUpdateVendor = async (data: UpdateVendor): Promise<void> => {
  const response = await VEClient.post(`/api/vendor/update`, data)
  return response.data
}

export const postTransactionVendor = async (data: SearchTransaction): Promise<TransactionVendorRes> => {
  const response = await VEClient.post(`/api/vendor/transaction`, data)
  return response.data
}

export const postProductVendor = async (data: SearchProduct): Promise<ProductVendorRes> => {
  const response = await VEClient.post(`/api/vendor/product`, data)
  return response.data
}