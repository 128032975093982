import { ReportNotEvaluateRes, ReportRankRes, ReportReasonRes, ReportEvaluatedRes, ReportPeriod, ReportListVendorRes, ReportHierarchyRes, FormReportBusinessData, ReportBusinessRes, ReportCompCode, ReportCompCodeListRes } from "@/models/report.model"
import { VEClient } from "@/utils/axios"

export const getReportHierarchy = async (): Promise<ReportHierarchyRes> => {
  const response = await VEClient.get(`/api/report/hierarchy`)
  return response.data
}

export const postReportRank = async (data: ReportPeriod): Promise<ReportRankRes> => {
  const response = await VEClient.post(`/api/report/rank`, data)
  return response.data
}

export const postReportNotEvaluate = async (data: ReportPeriod): Promise<ReportNotEvaluateRes> => {
  const response = await VEClient.post(`/api/report/notevaluate`, data)
  return response.data
}

export const postReportListNotEvaluate = async (data: ReportPeriod): Promise<ReportListVendorRes> => {
  const response = await VEClient.post(`/api/report/listnotevaluate`, data)
  return response.data
}

export const postReportEvaluated = async (data: ReportPeriod): Promise<ReportEvaluatedRes> => {
  const response = await VEClient.post(`/api/report/evaluated`, data)
  return response.data
}

export const postReportListEvaluated = async (data: ReportPeriod): Promise<ReportListVendorRes> => {
  const response = await VEClient.post(`/api/report/listevaluated`, data)
  return response.data
}

export const postReportBusiness = async (data: FormReportBusinessData): Promise<ReportBusinessRes> => {
  const response = await VEClient.post(`/api/report/business`, data)
  return response.data
}

export const postReportCompCode = async (data: ReportCompCode): Promise<ReportBusinessRes> => {
  const response = await VEClient.post(`/api/report/compcode`, data)
  return response.data
}

export const postReportCompCodeList = async (data: ReportCompCode): Promise<ReportCompCodeListRes> => {
  const response = await VEClient.post(`/api/report/compcodelist`, data)
  return response.data
}

export const postReportReason = async (data: ReportPeriod): Promise<ReportReasonRes> => {
  const response = await VEClient.post(`/api/report/reason`, data)
  return response.data
}