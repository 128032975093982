import { PrepareDataRes, PrepareData, EvaluationRes, SearchLatest, FormPostRes, EvaluationOneRes, SearchEvaluation, FormApprovePost, FormEvaluationPost, ListHalfRes, FormCancelPost, JsonEmail, ApproverListRes } from "@/models/evaluations.model"
import { PostData, ResponseData } from "@/models/ve.model"
import { VEClient } from "@/utils/axios"

export const getListHalf = async (): Promise<ListHalfRes> => {
  const response = await VEClient.get(`/api/evaluation/listhalf`)
  return response.data
}

export const postDashboard = async (data: SearchEvaluation): Promise<EvaluationRes> => {
  const response = await VEClient.post(`/api/evaluation/search`, data)
  return response.data
}

export const postEvaluateData = async (data: PostData): Promise<EvaluationOneRes> => {
  const response = await VEClient.post(`/api/evaluation/data`, data)
  return response.data
}

export const postApprove = async (data: FormApprovePost): Promise<void> => {
  const response = await VEClient.post(`/api/evaluation/approve`, data)
  return response.data
}

export const postMultiApprove = async (data: FormApprovePost[]): Promise<void> => {
  const response = await VEClient.post(`/api/evaluation/multiapprove`, data)
  return response.data
}

export const postPrepareData = async (data: PrepareData): Promise<PrepareDataRes> => {
  const response = await VEClient.post(`/api/evaluation/prepare`, data)
  return response.data
}

export const postLatestEvaluation = async (data: SearchLatest): Promise<EvaluationRes> => {
  const response = await VEClient.post(`/api/evaluation/latest`, data)
  return response.data
}

export const postLastScore = async (data: SearchLatest): Promise<EvaluationOneRes> => {
  const response = await VEClient.post(`/api/evaluation/last`, data)
  return response.data
}

export const postEvaCreate = async (data: FormEvaluationPost): Promise<FormPostRes> => {
  const response = await VEClient.post(`/api/evaluation/create`, data)
  return response.data
}

export const postEvaCancel = async (data: FormCancelPost): Promise<ResponseData> => {
  const response = await VEClient.post(`/api/evaluation/cancel`, data)
  return response.data
}

export const postApproverList = async (data: JsonEmail): Promise<ApproverListRes> => {
  const response = await VEClient.post(`/api/evaluation/approver`, data)
  return response.data
}