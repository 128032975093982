import { configureStore } from "@reduxjs/toolkit"
import { useDispatch } from "react-redux"

import adminReducer from "@/redux/slices/adminSlice"
import awsReducer from "@/redux/slices/awsSlice"
import evaluationReducer from "@/redux/slices/evaluationSlice"
import saptransactionReducer from "@/redux/slices/saptransactionSlice"
import aptransactionReducer from "@/redux/slices/aptransactionSlice"
import vpaytransactionReducer from "@/redux/slices/vpaytransactionSlice"
import allpaytransactionReducer from "@/redux/slices/allpaytransactionSlice"
import netsuitetransactionReducer from "@/redux/slices/netsuitetransactionSlice"
import storageReducer from "@/redux/slices/storageSlice"
import masterdataReducer from "@/redux/slices/masterdataSlice"
import vendorReducer from "@/redux/slices/vendorSlice"
import reportReducer from "@/redux/slices/reportSlice"

const reducer = {
  admin: adminReducer,
  aws: awsReducer,
  evaluation: evaluationReducer,
  storage: storageReducer,
  aptransaction: aptransactionReducer,
  saptransaction: saptransactionReducer,
  vpaytransaction: vpaytransactionReducer,
  allpaytransaction: allpaytransactionReducer,
  netsuitetransaction: netsuitetransactionReducer,
  masterdata: masterdataReducer,
  vendor: vendorReducer,
  report: reportReducer
}

export const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV === ("development" || "local" || "test"),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
