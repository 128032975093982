import { FileForm, FileRes, FileOneRes, FileRemove } from "@/models/storages.model"
import { VEClient } from "@/utils/axios"

export const getFileByID = async (id: string): Promise<FileRes> => {
  const response = await VEClient.get(`/api/upload/${id}`)
  return response.data
}

export const getFileByUUID = async (uuid: string): Promise<FileRes> => {
  const response = await VEClient.get(`/api/upload/uuid/${uuid}`)
  return response.data
}

export const removeFile = async (data: FileRemove): Promise<void> => {
  const response = await VEClient.post(`/api/upload/remove`, data)
  return response.data
}

export const postUpload = async (data: FileForm): Promise<FileOneRes> => {
  const response = await VEClient.post(`/api/upload/create`, data)
  return response.data
}