import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import * as awsService from "@/redux/services/awsService"
import { RootState } from "@/redux/store"
import { INF_CHECK_COST_CENTER_REQ, INF_CHECK_CREATE_WF_REQ, INF_CREATE_WF_REQ, REQUESTOR_WF } from "@/models/aws.model"

interface AWSState {
  requestors: REQUESTOR_WF[]
}

const initialState: AWSState = {
  requestors: []
}

export const postCheckCostCenter = createAsyncThunk("aws/costcenter", async (data: INF_CHECK_COST_CENTER_REQ) => {
  return await awsService.postCheckCostCenter(data)
})

export const postCheckCreateWF = createAsyncThunk("aws/check", async (data: INF_CHECK_CREATE_WF_REQ) => {
  return await awsService.postCheckCreateWF(data)
})

export const postCreateWF = createAsyncThunk("aws/create", async (data: INF_CREATE_WF_REQ) => {
  return await awsService.postCreateWF(data)
})

const awsSlice = createSlice({
  name: "aws",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(postCheckCostCenter.fulfilled, (state, action) => {
      state.requestors = action.payload.result.data.requestors
    })
  }
})

export const requestorSelector = (store: RootState): REQUESTOR_WF[] => store.aws.requestors

export default awsSlice.reducer