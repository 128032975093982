import { INF_CHECK_COST_CENTER_REQ, INF_CHECK_COST_CENTER_RES, INF_CHECK_CREATE_WF_REQ, INF_CREATE_WF_REQ, INF_CREATE_WF_RES } from "@/models/aws.model"
import { VEClient } from "@/utils/axios"

export const postCheckCostCenter = async (data: INF_CHECK_COST_CENTER_REQ): Promise<INF_CHECK_COST_CENTER_RES> => {
  const response = await VEClient.post(`/api/aws/costcenter`, data)
  return response.data
}

export const postCheckCreateWF = async (data: INF_CHECK_CREATE_WF_REQ): Promise<INF_CREATE_WF_RES> => {
  const response = await VEClient.post(`/api/aws/check`, data)
  return response.data
}

export const postCreateWF = async (data: INF_CREATE_WF_REQ): Promise<INF_CREATE_WF_RES> => {
  const response = await VEClient.post(`/api/aws/create`, data)
  return response.data
}