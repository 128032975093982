import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import * as adminService from "@/redux/services/adminService"
import { RootState } from "@/redux/store"
import { Admin, AdminForm, Setting, SettingForm } from "@/models/admins.model"
import { PostData } from "@/models/ve.model"

interface AdminState {
  admins: Admin[]
  settings: Setting
}

const initialState: AdminState = {
  admins: [],
  settings: {
    id: 0,
    half1_start: "",
    half1_end: "",
    half2_start: "",
    half2_end: "",
    sap_updated_date: "",
    ap_updated_date: "",
    netsuite_updated_date: "",
    vpay_updated_date: "",
    allpay_updated_date: "",
    bpps_ktrd_updated_date: "",
    bpps_kntd_updated_date: "",
    one_time_vendor_updated_date: "",
    cad_updated_date: "",
    product_updated_date: "",
    aws_cost_center_version: 0,
    aws_hierarchy_version: 0,
    aws_mapping_date: "",
    eva_auto_date: "",
    eva_vendor_inactive_date: "",
    eva_vendor_active_date: "",
    updated_by: "",
    updated_at: ""
  }
}

export const getSetting = createAsyncThunk("setting/list", async () => {
  return await adminService.getSetting()
})

export const putUpdateSetting = createAsyncThunk("setting/update", async (data: SettingForm) => {
  return await adminService.putUpdateSetting(data)
})

export const getAdmin = createAsyncThunk("admin/list", async () => {
  return await adminService.getAdmin()
})

export const postCheckAdmin = createAsyncThunk("admin/check", async (data: PostData) => {
  return await adminService.postCheckAdmin(data)
})

export const postCreateAdmin = createAsyncThunk("admin/create", async (data: AdminForm) => {
  return await adminService.postCreateAdmin(data)
})

export const deleteAdmin = createAsyncThunk("admin/delete", async (data: number) => {
  return await adminService.deleteAdmin(data)
})

export const updateAWS = createAsyncThunk("master/hierarchy", async () => {
  return await adminService.updateAWS()
})

export const updateAWSMapping = createAsyncThunk("master/mapping", async () => {
  return await adminService.updateAWSMapping()
})

export const updateKTRD = createAsyncThunk("vendor/ktrd", async () => {
  return await adminService.updateKTRD()
})

export const updateKNTD = createAsyncThunk("vendor/kntd", async () => {
  return await adminService.updateKNTD()
})

export const updateOTV = createAsyncThunk("batch/updateotv", async () => {
  return await adminService.updateOTV()
})

export const updateActive = createAsyncThunk("vendor/active", async () => {
  return await adminService.updateActive()
})

export const updateInactive = createAsyncThunk("vendor/inactive", async () => {
  return await adminService.updateInactive()
})

export const autoP = createAsyncThunk("batch/evaluate", async () => {
  return await adminService.autoP()
})

const adminSlice = createSlice({
  name: "admin",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSetting.fulfilled, (state, action) => {
      state.settings = action.payload.result
    }),
      builder.addCase(getAdmin.fulfilled, (state, action) => {
        state.admins = action.payload.result
      })
  }
})

export const settingSelector = (store: RootState): Setting => store.admin.settings
export const adminSelector = (store: RootState): Admin[] => store.admin.admins

export default adminSlice.reducer