import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import * as storageService from "@/redux/services/storageService"
import { FileForm, FileInfo, FileRemove } from "@/models/storages.model"

interface StorageState {
  files: FileInfo[]
}

const initialState: StorageState = {
  files: []
}

export const getFileByID = createAsyncThunk("upload/id", async (id: string) => {
  return await storageService.getFileByID(id)
})

export const getFileByUUID = createAsyncThunk("upload/uuid", async (uuid: string) => {
  return await storageService.getFileByUUID(uuid)
})

export const removeFile = createAsyncThunk("upload/remove", async (data: FileRemove) => {
  return await storageService.removeFile(data)
})

export const postUpload = createAsyncThunk("upload/create", async (data: FileForm) => {
  return await storageService.postUpload(data)
})

const storageSlice = createSlice({
  name: "storage",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFileByID.fulfilled, (state, action) => {
      state.files = action.payload.result
    })
    builder.addCase(getFileByUUID.fulfilled, (state, action) => {
      state.files = action.payload.result
    })
  }
})

export default storageSlice.reducer