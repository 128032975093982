import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import * as vpaytransactionService from "@/redux/services/vpaytransactionService"

import { VpayTransaction, SearchDashboard } from "@/models/vpaytransactions.model"

interface TransactionState {
  transactions: VpayTransaction[]
}

const initialState: TransactionState = {
  transactions: []
}

export const postDashboard = createAsyncThunk("vpay/dashboard", async (data: SearchDashboard) => {
  return await vpaytransactionService.postDashboard(data)
})

const vpaytransactionSlice = createSlice({
  name: "vpaytransaction",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => { }
})

export default vpaytransactionSlice.reducer