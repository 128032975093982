import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import * as evaluationService from "@/redux/services/evaluationService"
import { PrepareData, Evaluation, SearchLatest, SearchEvaluation, FormApprovePost, FormEvaluationPost, ListHalf, FormCancelPost, JsonEmail } from "@/models/evaluations.model"
import { PostData } from "@/models/ve.model"
import { RootState } from "@/redux/store"

interface EvaluationState {
  listhalf: ListHalf[]
  requestors: Evaluation[]
}

const initialState: EvaluationState = {
  listhalf: [],
  requestors: []
}

export const getListHalf = createAsyncThunk("evaluation/listhalf", async () => {
  return await evaluationService.getListHalf()
})

export const postDashboard = createAsyncThunk("evaluation/search", async (data: SearchEvaluation) => {
  return await evaluationService.postDashboard(data)
})

export const postEvaluateData = createAsyncThunk("evaluation/data", async (data: PostData) => {
  return await evaluationService.postEvaluateData(data)
})

export const postApprove = createAsyncThunk("evaluation/approve", async (data: FormApprovePost) => {
  return await evaluationService.postApprove(data)
})

export const postMultiApprove = createAsyncThunk("evaluation/multiapprove", async (data: FormApprovePost[]) => {
  return await evaluationService.postMultiApprove(data)
})

export const postPrepareData = createAsyncThunk("evaluation/prepare", async (data: PrepareData) => {
  return await evaluationService.postPrepareData(data)
})

export const postLatestEvaluation = createAsyncThunk("evaluation/latest", async (data: SearchLatest) => {
  return await evaluationService.postLatestEvaluation(data)
})

export const postLastScore = createAsyncThunk("evaluation/last", async (data: SearchLatest) => {
  return await evaluationService.postLastScore(data)
})

export const postEvaCreate = createAsyncThunk("evaluation/create", async (data: FormEvaluationPost) => {
  return await evaluationService.postEvaCreate(data)
})

export const postEvaCancel = createAsyncThunk("evaluation/cancel", async (data: FormCancelPost) => {
  return await evaluationService.postEvaCancel(data)
})

export const postApproverList = createAsyncThunk("evaluation/approver", async (data: JsonEmail) => {
  return await evaluationService.postApproverList(data)
})

const evaluationSlice = createSlice({
  name: "evaluation",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getListHalf.fulfilled, (state, action) => {
      state.listhalf = action.payload.result
    })
  }
})

export const listhalfSelector = (store: RootState): ListHalf[] => store.evaluation.listhalf

export default evaluationSlice.reducer