import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import * as NetsuitetransactionService from "@/redux/services/netsuitetransactionService"

import { NetsuiteTransaction, SearchDashboard } from "@/models/netsuitetransactions.model"

interface TransactionState {
  transactions: NetsuiteTransaction[]
}

const initialState: TransactionState = {
  transactions: []
}

export const postDashboard = createAsyncThunk("netsuite/dashboard", async (data: SearchDashboard) => {
  return await NetsuitetransactionService.postDashboard(data)
})

const netsuitetransactionSlice = createSlice({
  name: "netsuitetransaction",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => { }
})

export default netsuitetransactionSlice.reducer